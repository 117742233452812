<template>
    <div class="sale">
        <div class="shop-header">
            <h1>DeepSeek 充值套餐</h1>
            <p>选择最适合您的对话次数套餐</p>
        </div>
        
        <transition-group name="product-list" tag="div" class="product-container">
            <div v-for="product in products" :key="product.productId" class="product">
                <div class="product-inner">
                    <div class="product-name">
                        {{ product.productName }}
                    </div>
                    <div class="product-token">
                        {{ product.quota }}<span class="product-token-subscript">(条)</span>
                    </div>
                    <div class="product-price">
                        <span class="price-tag">￥{{ product.price.toFixed(2) }}</span>
                    </div>
                    <div class="product-buy" @click="payOrder(product.productId)">
                        <span class="buy-text">立即购买</span>
                    </div>
                    <div class="product-desc">
                        <span>{{ product.productDesc }}</span>
                    </div>
                </div>
            </div>
        </transition-group>

        <transition name="modal-fade">
            <div v-if="showModal" class="modal-backdrop">
                <div class="product-pay">
                    <div class="product-pay-weixin">
                        <i class="pay-icon">💰</i> 微信扫码支付
                    </div>
                    <div class="product-pay-url">
                        <qrcode-vue :value="payUrl" :size="250" level="H"></qrcode-vue>
                    </div>
                    <div class="product-pay-close" @click="handleCloseModal()">
                        😁 支付完成，点击关闭。去对话
                    </div>
                </div>
            </div>
        </transition>
<!--        <div class="product-pay-prompt" v-show="showModal">-->
<!--        <span>支付成功，自动充值。可直接去<span style="color: rgb(0,0,0); font-weight: bold;" @click="navigateToChat">【对话】</span>使用</span>-->
<!--        </div>-->
    </div>
</template>

<script>

import QrcodeVue from 'qrcode-vue';
import { debounce } from 'lodash';

export default {
    name: 'Sale', // 组件的名称
    components: {
        QrcodeVue // 注册QrcodeVue组件
    },
    data() {
        return {
            products: [], // 产品列表
            showModal: false, // 控制支付模态框的显示
            payUrl: '' // 支付链接
        };
    },
    methods: {
        // 查询产品列表的方法
        async queryProductListHandle() {
            const token = sessionStorage.getItem("accessToken");
            this.$http({
                url: "weixin/pay/query_product_list",
                method: "GET",
                headers: {
                    'Accesstoken': `${token}`
                }
            }).then((res) => {
                this.products = res;
            })
        },

        // 创建支付订单的方法
        async payOrder(productId) {
            const token = sessionStorage.getItem("accessToken");
            this.$http({
                url: "weixin/pay/create_pay_order",
                method: "POST",
                headers: {
                    'Accesstoken': `${token}`
                },
                params: {
                    productId: productId
                }
            }).then((res) => {
                this.payUrl = res;
                this.showModal = true; // 显示支付模态框
            })
        },
        // 关闭支付模态框的方法
        handleCloseModal() {
            this.showModal = false;
        },
        // 跳转到聊天页面的方法
        navigateToChat() {
            this.$router.push('/chat');
        }
    },
    mounted() {
        // 组件挂载后查询产品列表
        this.queryProductListHandle();
    }
};

</script>

<style scoped>
.sale {
    display: flex;
    flex-direction: column;
    font-family: "黑体", sans-serif;
    overflow-y: auto;
    padding: 30px 50px;
    background-color: #f9f9f9;
    min-height: 100vh;
    max-width: 1400px;
    margin: 0 auto;
}

.shop-header {
    text-align: center;
    margin-bottom: 30px;
}

.shop-header h1 {
    font-size: 28px;
    color: #333;
    margin-bottom: 10px;
}

.shop-header p {
    font-size: 16px;
    color: #666;
}

.product-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 30px;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

/* 响应式布局 */
@media (min-width: 768px) {
    .product-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 992px) {
    .product-container {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (min-width: 1200px) {
    .product-container {
        grid-template-columns: repeat(4, 1fr);
    }
}

.product {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    overflow: hidden;
    transition: all 0.3s ease;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
    background: #ffffff;
    display: flex;
}

.product:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
}

.product-inner {
    padding: 25px 20px;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: space-between;
}

.product-name {
    justify-content: center;
    font-weight: bold;
    font-size: 20px;
    margin: 0 auto 15px;
    text-align: center;
    color: #333;
    position: relative;
    padding-bottom: 15px;
}

.product-name::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 50px;
    height: 3px;
    background: linear-gradient(90deg, #018d01, #4CAF50);
    border-radius: 2px;
}

.product-token {
    justify-content: center;
    font-weight: bold;
    font-size: 38px;
    text-align: center;
    color: #020202;
    margin: 20px auto 0;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.05);
}

.product-token-subscript {
    font-size: 16px;
    color: #000000;
    font-weight: bold;
    vertical-align: baseline;
}

.product-desc {
    margin: 15px auto 5px;
    width: 90%;
    font-size: 13px;
    text-align: center;
    color: #666;
    line-height: 1.4;
}

.product-price {
    width: 100%;
    justify-content: center;
    text-align: center;
    margin: 20px auto 0;
}

.price-tag {
    font-weight: bold;
    font-size: 24px;
    color: #af0000;
    background: rgba(255, 235, 235, 0.5);
    padding: 8px 20px;
    border-radius: 20px;
    display: inline-block;
}

.product-buy {
    margin: 25px auto 10px;
    width: 80%;
    font-size: 18px;
    background: linear-gradient(45deg, #018d01, #4CAF50);
    color: #ffffff;
    border-radius: 25px;
    text-align: center;
    padding: 12px 0;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
    box-shadow: 0 4px 15px rgba(1, 141, 1, 0.2);
}

.product-buy::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
    transition: all 0.6s ease;
}

.product-buy:hover::before {
    left: 100%;
}

.product-buy:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 20px rgba(1, 141, 1, 0.3);
}

.buy-text {
    position: relative;
    z-index: 1;
}

/* 支付模态框样式 */
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.product-pay {
    position: relative;
    background-color: white;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
    max-width: 90%;
    animation: bounce-in 0.5s;
}

.product-pay-weixin {
    font-size: 20px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.pay-icon {
    font-style: normal;
}

.product-pay-url {
    background-color: #fff;
    padding: 15px;
    border-radius: 10px;
    border: 2px dashed #eaeaea;
    transition: all 0.3s ease;
}

.product-pay-url:hover {
    border-color: #018d01;
    box-shadow: 0 5px 15px rgba(1, 141, 1, 0.1);
}

.product-pay-close {
    margin-top: 20px;
    background: linear-gradient(45deg, #3b2300, #5a3700);
    color: #ffffff;
    border-radius: 50px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 15px;
    transition: all 0.3s ease;
    text-align: center;
}

.product-pay-close:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 15px rgba(59, 35, 0, 0.3);
}

/* 动画效果 */
.product-list-enter-active, .product-list-leave-active {
    transition: all 0.5s;
}

.product-list-enter, .product-list-leave-to {
    opacity: 0;
    transform: translateY(30px);
}

.modal-fade-enter-active, .modal-fade-leave-active {
    transition: opacity 0.3s;
}

.modal-fade-enter, .modal-fade-leave-to {
    opacity: 0;
}

@keyframes bounce-in {
    0% {
        transform: scale(0.8);
        opacity: 0;
    }
    70% {
        transform: scale(1.05);
        opacity: 1;
    }
    100% {
        transform: scale(1);
    }
}
</style>
